/* eslint-disable react/jsx-filename-extension */
import React, { Fragment } from "react"
import Media from "react-media"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../templates/pageLayout"
import ProjectCard from "../components/projectCard.js"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import * as portfolioStyles from "./portfolio.module.css"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default class Portfolio extends React.Component {
  constructor(props) {
    super(props)
    this.state = { windowWidth: null, displayLongerCopy: false }
  }

  componentDidMount() {
    let width = window.innerWidth
    this.setState({ windowWidth: width, displayLongerCopy: true })
  }

  render() {
    const data = useStaticQuery(graphql`
      query PortfolioQuery {
        allMdx(filter: { frontmatter: { type: { eq: "Project" } } }) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                type
                category
                subCategory
                services
                title
                description
                imgFile
                notable
              }
            }
          }
        }
      }
    `)
    const {
      breadcrumb: { crumbs },
    } = this.props.pageContext

    return (
      <Layout title="Our Portfolio">
        <div className="container">
          <div className={portfolioStyles.portfolioContainer}>
            <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              <h1>OUR PORTFOLIO</h1>
              <p>
                We take pride in our work and our portfolio is where we showcase
                selected projects.{" "}
                {this.state.displayLongerCopy && (
                  <Media
                    queries={{
                      small: "(max-width: 599px)",
                      medium: "(min-width: 600px)",
                    }}
                  >
                    {matches => (
                      <Fragment>
                        {matches.medium && (
                          <span>
                            So please don't be surprised if you don't see many
                            projects.{" "}
                          </span>
                        )}
                      </Fragment>
                    )}
                  </Media>
                )}
                To see our day to day work please visit our{" "}
                <a
                  href="https://www.facebook.com/tintexportsmouth/"
                  rel="nofollow"
                >
                  Facebook{" "}
                </a>
                and{" "}
                <a
                  href="https://www.instagram.com/tintex_portsmouth/"
                  rel="nofollow"
                >
                  {" "}
                  Instagram
                </a>
                !
              </p>
            </ScrollAnimation>
            <PortfolioContent data={data} />
          </div>
        </div>
      </Layout>
    )
  }
}

class PortfolioContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedsubCategory: "View All",
      selectedCategory: "View All",
      isFilterOpen: false,
    }

    this.handleCategorySort = this.handleCategorySort.bind(this)
    this.handlesubCategorySort = this.handlesubCategorySort.bind(this)
    this.toggleFilter = this.toggleFilter.bind(this)
  }

  handleCategorySort(e, category) {
    this.setState({
      selectedCategory: category,
      selectedsubCategory: "View All",
    })
    if (this.state.isFilterOpen) {
      this.toggleFilter()
    }
  }

  handlesubCategorySort(e, subCategory) {
    this.setState({
      selectedsubCategory: subCategory,
    })
    this.toggleFilter()
  }

  toggleFilter() {
    this.setState(state => ({
      isFilterOpen: !state.isFilterOpen,
    }))
  }

  render() {
    const { data } = this.props
    const categories = [
      "View All",
      "Automotive",
      "Residential",
      "Commercial",
      "Marine",
      "Aviation",
    ]
    const viewAllList = ["View All"]
    const automotiveSubCategories = [
      "Active Exhaust",
      "Ceramic Coating",
      "Classic Restoration",
      "Dechroming",
      "Decals",
      "Detailing",
      "End Of Lease",
      "Headlight Protection",
      "Headlight Restoration",
      "Lights Tinting",
      "PPF",
      "Paintwork Enhancement",
      "Paintwork Protection",
      "Paint Correction",
      "Vinyl Wrapping",
      "Water Repellent",
      "Window Tinting",
      "Wrap Removal",
    ]
    const residentialSubCategories = ["Solar Control Film"]
    const commercialSubCategories = ["Com. Solar Film"]
    const marineSubCategories = ["Mar. Solar Film"]
    const aviationSubCategories = ["Avi. Solar Film"]
    let projects = []
    let subCategories

    switch (this.state.selectedCategory) {
      case "Automotive":
        subCategories = viewAllList.concat(automotiveSubCategories)
        break
      case "Residential":
        subCategories = viewAllList.concat(residentialSubCategories)
        break
      case "Commercial":
        subCategories = viewAllList.concat(commercialSubCategories)
        break
      case "Marine":
        subCategories = viewAllList.concat(marineSubCategories)
        break
      case "Aviation":
        subCategories = viewAllList.concat(aviationSubCategories)
        break
      default:
        subCategories = viewAllList.concat(
          automotiveSubCategories,
          residentialSubCategories
        )
        break
    }

    const unSortedProjects = data.allMdx.edges.map((project, index) => {
      if (
        this.state.selectedCategory === "View All" &&
        this.state.selectedsubCategory === "View All"
      ) {
        return (
          <ProjectCard
            project={project.node.frontmatter}
            link={project.node.fields.slug}
            styles={portfolioStyles}
            key={index}
          />
        )
      } else if (
        project.node.frontmatter.category === this.state.selectedCategory &&
        project.node.frontmatter.services.includes(
          this.state.selectedsubCategory
        )
      ) {
        return (
          <ProjectCard
            project={project.node.frontmatter}
            link={project.node.fields.slug}
            styles={portfolioStyles}
            key={index}
          />
        )
      } else if (
        this.state.selectedsubCategory === "View All" &&
        project.node.frontmatter.category === this.state.selectedCategory
      ) {
        return (
          <ProjectCard
            project={project.node.frontmatter}
            link={project.node.fields.slug}
            styles={portfolioStyles}
            key={index}
          />
        )
      } else if (
        project.node.frontmatter.services.includes(
          this.state.selectedsubCategory
        ) &&
        this.state.selectedCategory === "View All"
      ) {
        return (
          <ProjectCard
            project={project.node.frontmatter}
            link={project.node.fields.slug}
            styles={portfolioStyles}
            key={index}
          />
        )
      }
    })

    let sortedProjects = unSortedProjects.sort((a, b) => {
      if (a.props.project.notable < b.props.project.notable) {
        return 1
      }
      if (a.props.project.notable > b.props.project.notable) {
        return -1
      }
      return 0
    })

    return (
      <div id={portfolioStyles.portfolioBody}>
        <div className={portfolioStyles.filtersContainer}>
          <div id="categoryFilter">
            {categories.map(category => {
              if (category === this.state.selectedCategory) {
                return (
                  <div
                    className="filterItem filterItem__active"
                    id={category + "Cat"}
                    onClick={e => {
                      this.handleCategorySort(e, category)
                    }}
                  >
                    {category}
                    <div className="filterItem__activeUnderline" />
                  </div>
                )
              } else {
                return (
                  <div
                    className="filterItem"
                    id={category + "Cat"}
                    onClick={e => {
                      this.handleCategorySort(e, category)
                    }}
                  >
                    {category}
                  </div>
                )
              }
            })}
          </div>
          <div className={portfolioStyles.subCategoryFilter}>
            <button
              className={
                "buttonStyle2" + " " + portfolioStyles.subCategoryFilterBtn
              }
              onClick={this.toggleFilter}
            >
              Filter
            </button>
            {this.state.isFilterOpen && (
              <div className={portfolioStyles.dropDownContent}>
                {subCategories.map(subCategory => {
                  if (subCategory === this.state.selectedsubCategory) {
                    return (
                      <div
                        className={portfolioStyles.subCategoryItem__active}
                        onClick={e => {
                          this.handlesubCategorySort(e, subCategory)
                        }}
                      >
                        {subCategory}
                      </div>
                    )
                  } else {
                    return (
                      <div
                        className={portfolioStyles.subCategoryItem}
                        onClick={e => {
                          this.handlesubCategorySort(e, subCategory)
                        }}
                      >
                        {subCategory}
                      </div>
                    )
                  }
                })}
              </div>
            )}
          </div>
        </div>
        <div className={portfolioStyles.portfolioGrid}>{sortedProjects}</div>
      </div>
    )
  }
}
